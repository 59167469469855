<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <header id="main-header">
            <h2>Exercises</h2>
          </header>
        </v-col>
      </v-row>
      <v-tabs
        v-model="dataTableTabs"
        :vertical="$vuetify.breakpoint.mobile"
        left
      >
        <v-tab
          v-for="item in filteredTableData"
          :key="item.title"
          left
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="dataTableTabs"
      >
        <v-tab-item
          v-for="(table, index) in filteredTableData"
          :key="table + index"
        >
          <v-container class="data-table">
            <v-data-table
              :headers="table.headers"
              :items="table.data"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item.date="{ item }">
                {{ formatDate(item.date) }}
              </template>
              <template v-slot:item.tooltip="{ item }">
                <span v-html="item.tooltip" />
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                  :color="statusData.colors[item.status]"
                  :text-color="statusData.textColors[item.status]"
                  class="rounded-sm"
                >
                  <v-icon
                    small
                    style="margin-right: 3px"
                  >
                    {{ statusData.icons[item.status] }}
                  </v-icon>
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import moment from '@/plugins/moment'
import { DateTime } from 'luxon'
import cloneDeep from 'lodash.clonedeep'
import { mapGetters, mapActions } from 'vuex'
import { mdiCheckCircle, mdiCloseCircle, mdiClockOutline } from '@mdi/js'
import { 
  activityHeaders,
} from '@/store/review/static'
export default {
  name: 'ActivityMonitor',
  data () {
    return {
      dataTableTabs: null,
      statusData: {
        icons: {
        Done: mdiCheckCircle,
        Missed: mdiCloseCircle,
        Scheduled: mdiClockOutline,
        Sent: mdiCheckCircle
        },
        colors: {
          Done: '#F0F7DB',
          Missed: '#FEEDF1',
          Scheduled: '#F5F5F5',
          Sent: '#F0F7DB',
        },
        textColors: {
          Done: '#6E8901',
          Missed: '#D10404',
          Scheduled: '#212121',
          Sent: '#6E8901'
        }
      },
      mdiCheckCircle
    }
  },
  computed: {
    ...mapGetters('review', [
      'days',
      'sessions',
      'schedules'
    ]),
    filteredTableData() {
      return this.tableData.filter(item => item.data.length > 0)
    },
    tableData() {
      return [
        {
          title: 'Activity scheduling',
          headers: activityHeaders['social-skills'],
          data: this._mapActivity('Activity scheduling')
        },
        {
          title: 'Affect and emotion',
          headers: activityHeaders['affect-and-emotion'],
          data: this._mapActivity('Affect and emotion')
        },
        {
          title: 'Cognitive',
          headers: activityHeaders['journals'],
          data: this._mapActivity('Cognitive')
        },
        {
          title: 'Custom reminder',
          headers: activityHeaders['custom-reminder'],
          data: this._mapActivity('Custom reminder')
        },
        {
          title: 'Enhancing safety',
          headers: activityHeaders['enhancing-safety'],
          data: this._mapActivity('Enhancing safety')
        },
        {
          title: 'Exposure',
          headers: activityHeaders['exposure'],
          data: this._mapActivity('Exposure')
        },
        {
          title: 'Journals',
          headers: activityHeaders['journals'],
          data: this._mapActivity('Journals')
        },
        {
          title: 'Parenting exercises',
          headers: activityHeaders['journals'],
          data: this._mapActivity('Parenting exercises')
        },
        {
          title: 'Parent-child activities',
          headers: activityHeaders['journals'],
          data: this._mapActivity('Parent-child activities')
        },
        {
          title: 'Parenting videos',
          headers: activityHeaders['journals'],
          data: this._mapActivity('Parenting videos')
        },
        {
          title: 'Relaxation',
          headers: activityHeaders['relaxation'],
          data: this._mapActivity('Relaxation')
        },
        // {
        //   title: 'Self-monitoring',
        //   headers: activityHeaders['journals'],
        //   data: this._mapActivity('Self-monitoring')
        // },
        // {
        //   title: 'Sleep log',
        //   headers: activityHeaders['social-skills'],
        //   data: this._mapActivity('Sleep')
        // },
        {
          title: 'Social skills',
          headers: activityHeaders['social-skills'],
          data: this._mapActivity('Social skills')
        },
      ]
    },
   
  },
  methods: {
    ...mapActions('review', [
      'initRemind'
    ]),

    _createSleepResult(data) {
      const toBed = this._makeTime(data.bed.time)
      const fellAsleep = this._makeTime(data.sleep.time)
      const finalAwake = this._makeTime(data.finalawake.time)
      const outOfBed = this._makeTime(data.outofbed.time)
      const times = data.wakeup.count == 0 || data.wakeup.count > 1 ? 'times' : 'time'
      const count = data.wakeup.count ? ' for a total of <strong>' + data.wakeup.duration + '</strong> minutes' : ''
      return 'On ' + moment(data.sleep.date).format('ddd, MMM D YYYY')
        + ' your client went to bed at <strong>' + toBed + '</strong>'
        + ' and fell asleep at <strong>' + fellAsleep + '</strong>'
        + ' and woke up <strong>' + data.wakeup.count + '</strong> '
        + times + count
        + ' and woke up at <strong>' + finalAwake + '</strong>'
        + ' and got out of bed at <strong>' + outOfBed + '</strong>'
    },
    _makeTime(time) {
      return time.h + ':' + time.mm + ' ' + time.A
    },
    _mapActivity(title) {

      // Occasionally an error in the dictionary will taint
      // legacy data. This will address it for unaccounted categories
      const exceptions = [
        {
          title: 'Emoji emotion matching',
          category: 'Affect and emotion'
        }
      ]

      // console.log('this.sessions', this.sessions)
      let data = []
      // sessions is collection of completed review activities
      const sessions = this.sessions.filter(session => {
        if (exceptions.some(item => item.title === session.reminderTitle && item.category === title)) {
          return true
        } else {
          return session.reminderTitle == title || session.reminderCategory == title
        }
      })
      // schedules is all scheduled activities that were not completed
      const schedules = this.schedules.filter(schedule => {
        if (exceptions.some(item => item.title === schedule.reminderTitle && item.category === title)) {
          return true
        } else {
          return schedule.reminderTitle == title || schedule.reminderCategory == title
        }
      })

      const flattened = []

      /**
       * array containing exercises that do not have duration or
       * quantity to be excluded
       */
      const blacklist = [
        'attend next appointment'
      ]

      // need to loop through each date array, clone the activity and
      // add to new array to display each incomplete activity and its status
      // need to ignore any that are not within the selected date range
      schedules.forEach(schedule => {
        schedule.date.forEach(d => {
          const startDate = moment().subtract(this.days.length, 'days')
          if (moment(d).isAfter(startDate)) {
            let tempObj = cloneDeep(schedule)
            tempObj.date = d
            tempObj.title = schedule.activity.title
            flattened.push(tempObj)
          }
        })
      })

      const dataset = [...sessions, ...flattened]
      // now we have to filter out duplicates where one is scheduled
      // and the other completed
      const filtered = dataset.reduce((acc, current) => {
        const found = acc.find(item => item.scheduleId === current.id)
        if (!found) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])

      filtered.forEach(activity => {
        if (activity.reminderCategory === 'Custom reminder') {
          if (!blacklist.includes(activity.activityName)) {
            let datetime = ''
            if (moment(activity.time[0], 'HH:mm').isValid()) {
              const utcTime = moment(activity.time[0], 'HH:mm').add(activity.utcOffset, 'minutes').format('HH:mm')
              datetime = activity.date + 'T' + utcTime
            } else {
              datetime = activity.date
            }
            
            const status = activity.progress > 75 || activity?.completed 
              ? 'Done' 
              : moment().isBefore(datetime) 
                ? 'Scheduled' : 'Missed'
            
            const time = activity?.completedAt?.seconds 
              ? moment.unix(activity.completedAt.seconds).format('h:mm a')
              : moment(activity.time[0], 'HH:mm').add(activity.utcOffset, 'minutes').format('h:mm a')
            const reminder = activity.remind ? activity.remind : ''
            const completedDate = activity?.completedAt?.seconds 
              ? moment.unix(activity.completedAt.seconds).format('YYYY-MM-DD')
              : activity.date
            data.push({
              date: completedDate,
              status: status,
              time: time,
              title: activity.title,
              reminder: reminder
            })
          }
        } else {
          let datetime = ''
          if (moment(activity.time[0], 'HH:mm').isValid()) {
            const utcTime = moment(activity.time[0], 'HH:mm').add(activity.utcOffset, 'minutes').format('HH:mm')
            datetime = activity.date + 'T' + utcTime
          } else {
            datetime = activity.date
          }

          // check to see if there is a video object in activity
          // which means it's a youtube video and there's no progress
          // associated with it; we just have to assume if it's in 
          // review it's been seen

          const statusMap = {
            'Done' : activity.progress > 75 || activity?.completed,
            'Scheduled' : moment().isBefore(datetime),
            'Missed' : 'Done' || 'Scheduled'
          }

          const status = Object.keys(statusMap).find(key => statusMap[key])
          const time = activity?.completedAt?.seconds 
            ? moment.unix(activity.completedAt.seconds).format('h:mm a') 
            : ''
          const completedDate = activity?.completedAt?.seconds 
            ? moment.unix(activity.completedAt.seconds).format('YYYY-MM-DD') 
            : moment(activity.date).format('YYYY-MM-DD')
        // currently not being used. Leaving here in case we add back to table
          const tooltip = activity.title == 'Sleep log' 
            ? this._createSleepResult(activity.data) 
            : activity.tooltip

          data.push({
            date: completedDate,
            status: status,
            time: time,
            title: activity.title,
            tooltip: tooltip
          })
        }
      })
      // sort all descending 
      data.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })
      return data
    },
    formatDate(date) {
      const dt = DateTime.fromISO(date)
      const formatted = dt.toLocaleString()
      return formatted
    },
  },
}
</script>
<style scoped>
  #main-header {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
 .chart-header {
    background-color: #FCFCFC;
    border: 1px solid #E0E0E0;
    border-radius: 12px;
    padding: 1rem;
  }

  >>>.v-tab {
    justify-content: left;
  }

  >>>.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding-top: 0.5rem;
    vertical-align: top;
  }

  .data-table {
    margin-top: 1rem;
    max-width: 100%;
  }

  header {
    margin-bottom: 1rem;
  }
</style>