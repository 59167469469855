<template>
  <div>
    <v-container
      id="chartContainer"
      class="chart-wrapper"
      style="position: relative;"
    >
      <LineChartGenerator
        ref="chart"
        :chart-options="options"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
      <v-overlay
        v-if="chartData.dataEmpty"
        absolute
        color="#fff"
        class="overlay"
      >
        <img
          src="@/assets/nodata.svg"
          alt="No data available to display"
        >
        <p style="color: #333;">
          No data for the {{ dateRange }}
        </p>
        <v-btn
          color="primary"
          class="button"
          @click="goToReminders"
        >
          Schedule some exercises
        </v-btn>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { severityLabels } from '@/store/review/static'
import moment from '@/plugins/moment'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

const emotionMap = {
  Fear: "fearful",
  Anxiety: "anxious",
  Anger: "angry",
  Sadness: "sad",
  Stress: "stressed"
}

const title = (tooltip) => {
  return tooltip[0].dataset.tooltips[tooltip[0].dataIndex].title
}

const label = (tooltip) => {
  return tooltip.dataset.tooltips[tooltip.dataIndex].body
}

// import { normalize } from 'path'
import { mapGetters, mapMutations } from 'vuex'
import { groupBy } from '@/helpers/array'
import { colorMap, chartOptions } from '@/store/review/static'

// import { mdiDownload } from '@mdi/js'
export default {
  name: "EmotionReviewGraph",
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'emotions-line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 250
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      hasChartData: false
    }
  },

  computed: {
    ...mapGetters("review", [
      "emotions",
      "days",
    ]),

    dateRange() {
      return (this.days.length === 8) ? 'past week' : 'past ' + this.days.length + ' days'
    },

    options() {
      let options = {...chartOptions}
      options.plugins.tooltip.callbacks = () => {
        return {
          title: title,
          label: label,
        }
      },
      options.scales = {
        y: {
          beginAtZero: true,
          suggestedMax: 4,
          ticks: {
            stepSize: 1,
            callback: function(value) {
              return severityLabels[value]
            }
          }
        }
      }
      return options
    },
    
    chartData() {
      /* group emtions by emotion, contained in 'title' */
      let emotionItems = groupBy(this.emotions, ['title'])
      /* contains assembled chart data */
      let data = []
      emotionItems.forEach(emotion => {
        /** need two parallel arrays to capture chart data
        * and tooltips separately to accommodate limitations
        * of ApexCharts in the way they handle x-axis labels
        * and tooltips
        * 
        * Fill each array with null according to the number of days in chosen range 
        */
        let itemData = Array(this.days.length).fill(null)
        let tooltips = Array(this.days.length).fill(null)
        /* loop through each emotion to build chart data object and tooltips */
        emotion.items.forEach((item) => {
          const convertedTime = moment.unix(item.completedAt.seconds).format('h:mm a')
          /* find the index of the day of the range in order
          align data properly according to date */
          const index = this.days.findIndex(
            day => day === item.dayFormat
          )
          if (index >= 0) {
            let emotionTitle = severityLabels[item.data.severity]
              + ' ' + emotionMap[emotion.name]
            let cope = item.data?.cope ? ' and coped with "' + item.data?.cope + '"'
              : ' and coped by doing nothing'

            if (!tooltips[index]?.body) {
              tooltips[index] = {
                body: []
              }
            }
            tooltips[index].title = 'Responses'
            tooltips[index].body.push([
              item.dayFormat + ' @ ' + convertedTime + ': ' +
              'The client felt ' + emotionTitle.toLowerCase(),
              cope
            ])
            
            itemData[index] = item.data.severity
          }
        })
        const arrayIsNull = itemData.every(item => item === null)
        if (!arrayIsNull) {
          data.push({
            label: emotion.name,
            backgroundColor: colorMap[emotion.name.toLowerCase()],
            borderColor: colorMap[emotion.name.toLowerCase()],
            data: itemData,
            tooltips: tooltips
          })
        }
      })

      // this is to display chart lines when there is no data
      // this is only for the overlay to have a background while
      // prompting the user to do some activities
      let dummyData = Array.from({length: this.days.length}, () => Math.floor(Math.random() * 5))

      let chartData = {
        dataEmpty: data.length === 0,
        labels: this.days,
        datasets: data.length === 0 ? [{label: 'Emotions', data: dummyData}] : data
      }
      this.setHasChartDataBoolean(!!data.length)
      return chartData
    },
  },

  async mounted() {
    await this.setPrintChartData({
      type: 'Emotions', 
      data: this.chartData.datasets
    })
  },

  methods: {
    ...mapGetters('review', ['xAxesFormat']),
    ...mapMutations('review', ['setHasChartData', 'setPrintChartData']),
    goToReminders() {
      this.$router.push({path: '/dashboard/reminders/remind'})
    },
    setHasChartDataBoolean(hasChartData) {
      this.setHasChartData({
        type: 'Emotions',
        hasData: hasChartData
      })
    }
    
  }
}
</script>
<style scoped>
>>>.v-overlay__content {
  text-align: center;
}
</style>