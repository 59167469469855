<template>
  <div>
    <v-container
      id="chartContainer"
      class="chart-wrapper"
    >
      <header>
        <v-radio-group
          v-model="yAxisType"
          row
        >
          <v-radio
            label="Frequency"
            value="Frequency"
          />
          <v-radio
            label="Duration"
            value="Duration"
          />
        </v-radio-group>
      </header>
      <div style="position: relative;">
        <LineChartGenerator
          ref="behaviorsChart"
          :chart-options="options"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
        <v-overlay
          v-if="chartData.dataEmpty"
          absolute
          color="#fff"
          class="overlay"
        >
          <img
            src="@/assets/nodata.svg"
            alt="No data available to display"
          >
          <p style="color: #333;">
            No data for the {{ dateRange }}
          </p>
          <v-btn
            color="primary"
            class="button"
            @click="goToReminders"
          >
            Schedule some exercises
          </v-btn>
        </v-overlay>
      </div>
    </v-container>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import moment from '@/plugins/moment'
import { groupBy } from '@/helpers/array'
import { mapGetters, mapMutations } from 'vuex'
import { 
  colorMap,
  chartOptions
} from '@/store/review/static'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'
import { mdiConsolidate } from '@mdi/js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

// const title = (tooltip) => {
//   return tooltip[0].dataset.tooltips[tooltip[0].dataIndex].title
// }

// const label = (tooltip) => {
//   return tooltip.dataset.tooltips[tooltip.dataIndex].body
// }

export default {
  name: 'ThoughtsOrBehaviorsGraph',
  components: {
    LineChartGenerator
  },
  props: {
    chartType: {
      type: String,
      default: 'Thoughts'
    },
    chartId: {
      type: String,
      default: 'thoughts-and-behaviors-line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dataEmpty: false,
      hasChartData: false,
      isQuantity: false,
      yAxisType: 'Frequency',
    }
  },
  computed: {
    ...mapGetters('review', [
      'days',
      'sessions'
    ]),
    dateRange() {
      return (this.days.length === 8) ? 'past week' : 'past ' + this.days.length + ' days'
    },

    options() {
      let options = {...chartOptions}
      options.scales.y['max'] = this.max
      return options
    },

    chartData() {
      /**
       * because the data is indexed by the activity name
       * and there are disparities between the name and title
       * we need to map them.
       */
      const indexMap = {
        'intrusivethoughts': 'intrusive',
        'checking': 'checkingbehavior'
      }
      let data = []

      /**
       * array containing exercises that do not have duration or 
       * quantity to be excluded
       */ 
      const blacklist = [
        'sleeplog',
        'patienthealthquestionnaire(phq-8)',
        'pictureemotionmatching',
        'identifyingfeelings',
      ]
      /**
       * group the data by the exercise title in order to 
       * logically arrange the data
       */
      const groupedSessions = groupBy(this.sessions, ['activityTitle'])
      groupedSessions.forEach(session => { 
        if(session.name == this.chartType) {
          const groupedSections = groupBy(session.items, ['title'])
          groupedSections.forEach((gs) => {  
            let quantityData = Array(this.days.length).fill(null)
            let durationData = Array(this.days.length).fill(null)
            let tooltips = Array(this.days.length).fill(null)
            let thoughtOrBehavior = {}
            const groupedByDay = groupBy(gs.items, ['date'])
            groupedByDay.forEach(group => {
              // reset for each day
              let dayTotalDuration = 0
              let dayTotalQuantity = 0
              const itemDay = moment(group.name).format(this.xAxesFormat())
              /* find the index of the day of the range in order
              align data properly according to date */
              const dayIndex = this.days.findIndex(
                day => day === itemDay
              )
              let tooltipBody = []
              group.items.forEach(item => {               
                /**
                 * Because our data is indexed by the name of the exercise
                 * we need to capture and normalize the index in order
                 * to reference the data object
                 * i.e. data['instrusive'], which by the way does not follow
                 * the naming convention of intrusivethoughts. 
                 * This data must be fixed someday...
                 */
                const thoughtOrBehaviorIndex = item.title.toLowerCase().replace(/\s/g, '')
                
                if(!blacklist.includes(thoughtOrBehaviorIndex)) {
                
                  // find the name-indexed data object
                  let tobi = indexMap[thoughtOrBehaviorIndex] ? 
                    indexMap[thoughtOrBehaviorIndex] : 
                    thoughtOrBehaviorIndex
                  
                  tooltips[dayIndex] = {
                    title: moment(item.date).format('MMM D') + ' - ' + item.title,
                    body: ''
                  }
                  thoughtOrBehavior.label = item.title
                  thoughtOrBehavior.backgroundColor = colorMap[item.title.toLowerCase()]
                  thoughtOrBehavior.borderColor = colorMap[item.title.toLowerCase()]
                  const activityIndex = this._toLowerCase(item.title)
                  const completed = moment.unix(item.completedAt.seconds).format('h:mm a') 
                  // if activity was dismissed with no engagement
                  // patient[activityIndex] === false
                  // need to grab this and add it to the tooltips as a non-event
                  if (!item.data?.[activityIndex] && !item.data.patient) {
                    tooltipBody.push('No activity for ' + item.title + ' at ' + completed)
                  } else if (!item.data?.patient?.[activityIndex]) {
                    tooltipBody.push('No activity for ' + item.title + ' at ' + completed)
                  }
                  if (item?.data?.patient[tobi]) {
                    item.data.patient[tobi].forEach((tob) => {
                      dayTotalQuantity = tob.quantity 
                        ? dayTotalQuantity + parseInt(tob.quantity)
                        : dayTotalQuantity += 1
                      dayTotalDuration = tob.duration 
                        ? dayTotalDuration + parseInt(tob.duration)
                        : null
                      let what = this._capitalize(tob?.what)
                      let why = tob?.why ? ' because ' + tob.why : ''
                      let quantity = tob.quantity ? ' ' + tob.quantity + ' times' : ''
                      let duration = tob.duration ? ' for ' + tob.duration + ' minutes' : ''
                      tooltipBody.push(what + quantity + duration + why + ' at ' + completed)
                    })
                  }
                }
                // separate arrays to allow switching data source for graph
                quantityData[dayIndex] = dayTotalQuantity
                durationData[dayIndex] = dayTotalDuration
                tooltips[dayIndex].body = tooltipBody //this._cleanTooltip(item.tooltip)
              })
            })
            thoughtOrBehavior.data = this.yAxisType == 'Frequency' ? quantityData : durationData
            thoughtOrBehavior.tooltips = tooltips
            
            // don't add empty arrays
            if (!thoughtOrBehavior.data.every(element => element === null)) {
              data.push(thoughtOrBehavior)
            }
          })
        }     
      })

      let dummyData = Array.from({length: this.days.length}, () => Math.floor(Math.random() * 10))

      let chartData = {
        dataEmpty: data.length === 0,
        labels: this.days,
        datasets: data.length === 0 ? [{label: this.chartType, data: dummyData}] : data
      }
      this.setHasChartDataBoolean(!!data.length)
      return chartData
    },
    max() {
      let ranges = []
      this.chartData.datasets.forEach(d => {
        ranges = [...ranges, ...d.data]
      })
      let max =  Math.max(...ranges)
      return max > 9 ? max += parseInt(max * 0.15) : 12
    },
  },

  async mounted() {
    await this.setPrintChartData({
      type: this.chartType, 
      data: this.chartData.datasets
    })
  },

  methods: {
    ...mapGetters('review', ['xAxesFormat']),
    ...mapMutations('review', ['setHasChartData', 'setPrintChartData']),
    _capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    _toLowerCase(title) {
      const titleArray = title.split(' ')
      let lowerCaseArray = titleArray.map(t => {
        return t.charAt(0).toLowerCase() + t.slice(1)
      })
      return lowerCaseArray.join('')
    },
    _toProperCase(title) {
      const titleArray = title.split(' ')
      if (titleArray.length > 1) {
        let properCaseArray = titleArray.map(t => {
          return t.charAt(0).toUpperCase() + t.slice(1)
        })
        return properCaseArray.join('')
      }
      return title.toLowerCase()
    },
    _cleanTooltip(tips) {
      // tooltips need to be split by the <span> 
      // and then scrubbed of all markup
      const tip = tips.split('<hr>')
      const cleaned = tip.map(t => {
        const arr = t.split('<span class="font-weight-medium">')
        const newArr = arr.filter(a => a !== '')
        const scrubbed = newArr.map(a => {
          return a.replace(/<(.|\n)*?>/g, '')
        })
        scrubbed[0] = scrubbed[0].charAt(0).toUpperCase() + scrubbed[0].slice(1)
        const cleaned = scrubbed.slice(1, scrubbed.length).join('')
        return [
          scrubbed[0],
          cleaned
        ]
      })
      
      return cleaned
    },
    goToReminders() {
      this.$router.push({path: '/dashboard/reminders/remind'})
    },
    setHasChartDataBoolean(hasChartData) {
      this.setHasChartData({
        type: this.chartType,
        hasData: hasChartData
      })
    }
  },
  
}
</script>
<style scoped>
>>>.v-overlay__content {
  text-align: center;
}
</style>