<template>
  <div>
    <v-container
      id="chartContainer"
      class="chart-wrapper"
    >
      <div style="position: relative;">
        <LineChartGenerator
          ref="chart"
          :chart-options="options"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
        <v-overlay
          v-if="chartData.dataEmpty"
          absolute
          color="#fff"
          class="overlay"
        >
          <img
            src="@/assets/nodata.svg"
            alt="No data available to display"
          >
          <p style="color: #333;">
            No data for the {{ dateRange }}
          </p>
          <v-btn
            color="primary"
            class="button"
            @click="goToReminders"
          >
            Schedule some exercises
          </v-btn>
        </v-overlay>
      </div>
    </v-container>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import moment from '@/plugins/moment'
import { groupBy } from '@/helpers/array'
import { mapGetters, mapMutations } from 'vuex'
import { 
  colorMap,
  chartOptions
} from '@/store/review/static'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

const title = (tooltip) => {
  return tooltip[0].dataset.tooltips[tooltip[0].dataIndex].title
}

const label = (tooltip) => {
  return tooltip.dataset.tooltips[tooltip.dataIndex].body
}

export default {
  name: 'DistressingSituationsGraph',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'distressing-situations-line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dataEmpty: false,
      hasChartData: false,
      isQuantity: false,
    }
  },
  computed: {
    ...mapGetters('review', [
      'days',
      'sessions'
    ]),
    dateRange() {
      return (this.days.length === 8) ? 'past week' : 'past ' + this.days.length + ' days'
    },

    options() {
      let options = {...chartOptions}
      options.scales.y['max'] = this.max
      return options
    },
    distressingSituations() {
      const situations = this.sessions.filter(session => session.activityName === 'distressing situations')
      let quantityData = Array(this.days.length).fill(null)
      let tooltips = Array(this.days.length).fill(null)
      let tt = []
      situations.forEach(situation => {
        const itemDay = moment(situation.date).format(this.xAxesFormat())
        /* find the index of the day of the range in order
        align data properly according to date */
        const dayIndex = this.days.findIndex(
          day => day === itemDay
        )
        let situations = situation.data.patient.situations
        // checking to see if we have situations or tooltips
        // because a client can dismiss the reminder with
        // having no situations at that time
        // quantifying how many situations happen each day
        if (situations?.length) {
          quantityData[dayIndex] += situations.length
        } else {
          quantityData[dayIndex] = 0
        }
        tt.push({
          title:
            moment(situation.date).format("MMM D [@] ")
            + moment.unix(situation.completedAt.seconds).format('h:mm a') + ' - '
            + 'Distressing situations',
          body: situation.tooltip ? this._cleanTooltip(situation.tooltip) :
            ['No situations at this time'],
          index: dayIndex
        })
        
      })
      
      // need to group items by day in order to iterate through
      // tooltips to build the tooltip object
      const grouped = groupBy(tt, ['index'])
      grouped.forEach(tip => {
        let body = []
        let tooltip = {
          body: [],
          title: ''
        }
        
        tip.items.forEach(item => {
          tooltip.title = item.title
          body.push(item.body)
        })

        if (!tooltips[tip.name]) {
          tooltips[tip.name] = []
        }
        tooltip.body = body.flat(2)
        tooltips[tip.name] = tooltip
      })
      return {
        label: 'Distressing situations',
        backgroundColor: colorMap['distressing situations'],
        borderColor: colorMap['distressing situations'],
        data: quantityData,
        tooltips: tooltips
      }

    },

    chartData() {
      const dummyData = Array.from({length: this.days.length}, () => Math.floor(Math.random() * 10))
      const hasData = this.distressingSituations.data.some(ds => null !== ds)
      let chartData = {
        dataEmpty: !hasData,
        labels: this.days,
        datasets: !hasData
          ? [{label: 'Distressing situations', data: dummyData}]
          : [this.distressingSituations]
      }

      this.setHasChartDataBoolean(hasData)
      return chartData
    },
    max() {
      let ranges = []
      this.chartData.datasets.forEach(d => {
        ranges = [...ranges, ...d.data]
      })
      let max =  Math.max(...ranges)
      return max > 9 ? max += parseInt(max * 0.15) : 12
    },
  },

  async mounted() {
    await this.setPrintChartData({
      type: 'DistressingSituations', 
      data: this.chartData.datasets
    })
  },

  methods: {
    ...mapGetters('review', ['xAxesFormat']),
    ...mapMutations('review', ['setPrintChartData', 'setHasChartData']),
    goToReminders() {
      this.$router.push({path: '/dashboard/reminders/remind'})
    },
    _cleanTooltip(tips) {
      // tooltips need to be split by the <span> 
      // and then scrubbed of all markup
      const tip = tips.split('<hr>')
      const cleaned = tip.map(t => {
        const arr = t.split('<span class="font-weight-medium">')
        const newArr = arr.filter(a => a !== '')
        const scrubbed = newArr.map(a => {
          return a.replace(/<(.|\n)*?>/g, '')
        })
        scrubbed[0] = scrubbed[0].charAt(0).toUpperCase() + scrubbed[0].slice(1)
        const cleaned = scrubbed.slice(1, scrubbed.length).join('')
        return [
          scrubbed[0],
          cleaned
        ]
      })
      
      return cleaned
    },
    setHasChartDataBoolean(hasChartData) {
      this.setHasChartData({
        type: 'DistressingSituations',
        hasData: hasChartData
      })
    }
  },
  
}
</script>
<style scoped>
>>>.v-overlay__content {
  text-align: center;
}
</style>