<template>
  <div class="noselect">
    <Welcome v-if="!haveChosenClient" />
    <Loading
      v-else
      :state="loading"
    >
      <div class="review">
        <div
          class="page-header-wrapper"
        >
          <page-header
            sticky
            :has-scroll-border="false"
          >
            <h1 class="review-header-title">
              Review results
            <!-- <title-with-client title="Review results" /> -->
            </h1>
          </page-header>
        </div>
        <div
          class="review-header"
          :class="{scrolled}"
        >
          <ReviewSections />
        </div>

        <!-- <div v-if="haveMonitoring"> -->
        <div class="table-container">
          <header>
            <h2>Self-monitoring</h2>
            <SelectPeriod />
          </header>
          <v-divider />
          <!-- <select-self-reminder /> -->
          <v-tabs
            v-model="tab"
            align-with-title
          >
            <v-tab key="emotions">
              Emotions
            </v-tab>
            <v-tab key="thoughts">
              Thoughts
            </v-tab>
            <v-tab key="behaviors">
              Behaviors
            </v-tab>
            <v-tab key="distressing">
              Distressing situations
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="emotions">
              <emotion-review-graph
                ref="emotions-line-chart"
                chart-id="emotions-line-chart"
                :height="320"
              />
            </v-tab-item>
            <v-tab-item key="thoughts">
              <thoughts-behaviors-graph
                ref="thoughts-line-chart"
                chart-id="thoughts-line-chart"
                :height="320"
                chart-type="Thoughts"
              />
            </v-tab-item>
            <v-tab-item key="behaviors">
              <thoughts-behaviors-graph
                ref="behaviors-line-chart"
                chart-id="behaviors-line-chart"
                :height="320"
                chart-type="Behaviors"
              />
            </v-tab-item>
            <v-tab-item key="distressing">
              <distressing-situations-graph
                ref="distressing-situations-line-chart"
                chart-id="distressing-situations-line-chart"
                :height="320"
              />
            </v-tab-item>
          </v-tabs-items>
          
          <div class="cta">
            <v-tooltip
              v-if="!isAdminOrHasSubscription"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ mdiInformation }}
                </v-icon>
              </template>
              <span>Upgrade to PRO to activate printing and exporting of data</span>
            </v-tooltip>
            <v-btn
              :disabled="!isPrintDisabled"
              class="primary"
              @click="$event => print()"
            >
              Print chart
            </v-btn>
            <v-btn
              :disabled="!isPrintDisabled"
              class="primary"
              @click="exportToCSV"
            >
              Export data
            </v-btn>
          </div>
          <activity-monitor />
          
          <!-- Maybe read this someday when we want to display tabular data -->
          <!-- <thoughts-behaviors-table /> -->
        </div>
      </div>
    </loading>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmotionReviewGraph from '@/views/dashboard/review/components/EmotionReviewGraph'
import ActivityMonitor from '@/views/dashboard/review/components/ActivityMonitor'
import Loading from '@/views/dashboard/review/components/Loading'
import Welcome from '@/views/dashboard/Welcome'
import SelectPeriod from '@/views/dashboard/review/SelectPeriod'
import ReviewSections from './Sections'
import PageHeader from '@/components/dashboard/PageHeader.vue'
// import ThoughtsGraph from '@/views/dashboard/review/components/ThoughtsGraph'
import ThoughtsAndBehaviorsGraph from '@/views/dashboard/review/components/ThoughtsAndBehaviorsGraph'
import DistressingSituationsGraph from '@/views/dashboard/review/components/DistressingSituationsGraph'
import { severityLabels as emotionsLabels, exportHeaders } from '@/store/review/static'
import * as htmlToImage from 'html-to-image'
import { jsPDF } from 'jspdf'
import csvDownload from 'json-to-csv-export'
import { mdiInformation } from '@mdi/js'

export default {
  name: 'ReviewMonitoringSection',
  components: {
    'emotion-review-graph': EmotionReviewGraph,
    'activity-monitor': ActivityMonitor,
    'distressing-situations-graph': DistressingSituationsGraph,
    'thoughts-behaviors-graph': ThoughtsAndBehaviorsGraph,
    // 'behaviors-graph': BehaviorsGraph,
    ReviewSections,
    SelectPeriod,
    Welcome,
    Loading,
    PageHeader
  },
  data() {
    return {
      mdiInformation,
      chartHasData: false,
      dataType: [
        'emotions',
        'thoughts', 
        'behaviors',
        'distressingSituations'
      ],
      emotionsLabels,
      exportHeaders,
      hasData: [
        'Emotions',
        'Thoughts', 
        'Behaviors',
        'DistressingSituations'
      ],
      scrolled: false,
      tab: 0,
      tabIds: [
        'emotions', 
        'thoughts',
        'behaviors', 
        'distressing-situations'
      ],
      
    }
  },
  computed: {
    ...mapGetters('clients', ['client', 'haveChosenClient']),
    ...mapGetters('authentication', ['isUserLoggedIn', 'user']),
    ...mapGetters('subscription', ['hasActiveSubscription']),
    ...mapGetters('review', [
      'days',
      'loading', 
      'haveMonitoring', 
      'from', 
      'diffDays', 
      'hasChartData',
      'printChartData',
    ]),
    ...mapGetters({ user: 'authentication/user' }),
    isPrintDisabled() {
      const dataType = `has${this.hasData[this.tab]}Data`
      return this.hasChartData(dataType) && this.isAdminOrHasSubscription
    },
    isAdminOrHasSubscription() {
      return this.user.role === 'admin' || this.hasActiveSubscription
    },
  },
  created() {
    this.segmentTrack({ eventName: 'Dashboard View' })
    this.unwatchIsLiveProp = this.$watch('client.id', (newVal) => {
      if (newVal) {
        this.init(this.$route.meta.reminderType)
        this.unwatchIsLiveProp()
      }
    })
    this.setSelfReminder('Emotions')
    let lastKnownScrollPosition = 0;
    let throttling = false;

    this.scrollListener = document.addEventListener('scroll', () => {
      lastKnownScrollPosition = window.scrollY;

      if (!throttling) {
        window.requestAnimationFrame(() => {
          this.scrolled = lastKnownScrollPosition > 0
          throttling = false;
        });

        throttling = true;
      }
    });
  },
  beforeDestroy() {
    // Must stop listening to scrolls
    document.removeEventListener('scroll', this.scrollListener);
  },
  mounted() {
    this.reset()
    this.init(this.$route.meta.reminderType)
  },
  methods: {
    ...mapActions('app', ['fetchDocs']),
    ...mapActions('review', ['init']),
    ...mapActions('analytics', ['segmentTrack']),
    ...mapMutations('review', ['set', 'reset', 'setSelfReminder']),
    async print() {
      const chartType = this.dataType[this.tab]
      const graphId = this.tabIds[this.tab] + '-line-chart'
      const chartData = await this.printChartData(chartType)
      let tooltips = []
      chartData.forEach(data => {
        let tips = []
        data.tooltips.forEach(tt => {
          if (tt) {
            if (chartType === 'distressingSituations') {
              tips.push(tt.title)
            }
            tips.push(tt.body.join('\n'))
          }
        })
        tooltips.push({
          label: data.label,
          tips: tips
        })
      })

      const chartContainer = document.getElementById(graphId)
      const client = this.client.name
      htmlToImage.toPng(chartContainer, {skipFonts: true}).then(dataUrl => {
        const img = new Image()
        img.src = dataUrl
        img.crossOrigin = 'anonymous'
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'in'
        })
        pdf.addImage(img, 'PNG', 0.5, 1, 7.25, 2.25)
        pdf.setFillColor(255, 255, 255, 0)
        pdf.setFontSize(20)
        pdf.text(`${this.hasData[this.tab]} review chart for ${client}`, 0.5, 0.5)
        let totalTips = []
        tooltips.forEach(tip => {
          totalTips.push(tip.label)
          tip.tips.forEach(tip => {
            totalTips.push(tip)
          })
          totalTips.push(' ')
        })
        pdf.setFontSize(12)
        pdf.text(totalTips, 0.5, 3.5, {maxWidth: 7})
        pdf.save(`${this.hasData[this.tab]} Data - ${new Date().toLocaleDateString()}.pdf`)
      })
    },
    async exportToCSV() {
      const chartType = this.dataType[this.tab]
      const chartData = await this.printChartData(chartType)
      const yAxisLabels = `${chartType}Labels`
      let data = []
      chartData.forEach(dataItem => {
        for (let i = 0; i < dataItem.data.length; i++) {
          if (dataItem.data[i]) {
            switch(chartType) {
              case 'emotions':
                data.push({
                  Date: this.days[i],
                  Label: dataItem.label,
                  Measure: this[yAxisLabels][dataItem.data[i]],
                  Response: dataItem.tooltips[i].body.join()
                })
                break
              case 'thoughts':
              case 'behaviors':
              case 'distressingSituations':
                data.push({
                  Date: this.days[i],
                  Label: dataItem.label,
                  Total: dataItem.data[i],
                  Response: dataItem.tooltips[i].body.join('\n')
                })
                break
            }
            
          }
        }
      })
      const csv = {
        data: data,
        filename: `${this.hasData[this.tab]} Data - ${new Date().toLocaleDateString()}`,
        delimiter: ',',
        headers: this.exportHeaders[chartType].headers
      }
      csvDownload(csv)
    }
  },
  head: {
    title: {
      inner: 'Review',
    },
  },
}
</script>

<style scoped>
  .review-header {
    background-color: #fff;
    left: 0;
    margin: 3rem 0 0 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 6;
  }

  .scrolled {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    .review-header {
      left: unset;
      margin: 3rem auto;
      max-width: 1280px;
      padding-bottom: 1rem;
      width: calc(100% - 2rem)
    }
  }

  .chart-header {
    display: flex;
  }
  .select-period {
    flex: 1;
  }

  .cta {
    display: flex;
    flex: 5;
    justify-content: right;
  }

  .cta button {
    margin-left: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
  }
</style>

<style lang="scss">
@import '../../../theme/review';

.v-window {
  overflow: visible;
}

.chart-wrapper {
  max-width: 100%;
}

.review .table-container {
  width: 100%;
  margin: 9rem auto 0;
}

@media (min-width: 1139px) {
  .review .table-container {
    margin: 2rem auto 0;
  }
}

.review {
  align-items: center;
  display: flex;
  flex-direction: column;
}

</style>


